.toggler {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: center;
  width: 40px;
}

.menu {
  max-height: 256px;
  max-width: 256px;
  overflow-y: auto;
}
