.root {
  display: flex;
  justify-content: center;
}

.content {
  color: var(--color-on-background);
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 100%;
  word-break: break-word;
}
