.toggler {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: center;
  width: 40px;
}

.togglerIcon {
  background-color: var(--color-surface-variant);
  color: var(--color-on-surface-variant);
  position: relative;
}

.togglerIcon::after {
  background-color: var(--color-on-surface-variant);
  content: ' ';
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition-delay: var(--transition-delay);
  transition-duration: var(--transition-duration);
  transition-property: var(--transition-property);
  transition-timing-function: var(--transition-timing-function);
}

.togglerIcon:hover::after {
  content: ' ';
  opacity: 0.16;
}

.togglerIcon:focus::after {
  content: ' ';
  opacity: 0.24;
}

.togglerIcon:active::after {
  content: ' ';
  opacity: 0.24;
}
