.sticky {
  z-index: 1000;
}

.root {
  align-items: center;
  border-radius: 0;
  display: flex;
  height: 64px;
  justify-content: center;
}

.container {
  flex: 1;
  max-width: var(--size-container);
}

.content {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.leftSideContent {
  display: flex;
  justify-content: left;
  width: 128px;
}

.rightSideContent {
  display: flex;
  gap: calc(var(--space-gap) * 2);
  justify-content: right;
  width: 128px;
}

.logo {
  max-height: 32px;
}
