.root {
  min-height: 100%;
  width: 100%;
}

.container {
  margin: auto;
  max-width: 100%;
  padding: 8px;
  width: var(--size-container);
}
