.menu {
  background-color: transparent !important;
}

.menuItem {
  justify-content: flex-start;
}

.menuItemIcon {
  text-align: center;
  width: 24px;
}
