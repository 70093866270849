.root {
  align-items: center;
  display: flex;
  justify-content: center;
}

.horizontal {
  flex-direction: row;
  min-height: var(--space-7x);
}

.vertical {
  flex-direction: column;
  min-height: var(--space-10x);
}

.indicator {
  padding: var(--space-2x);
}

.title {
  padding: var(--space-2x);
}

.horizontal.large {
  min-height: var(--space-7x);
}

.vertical.large {
  min-height: var(--space-11x);
}
